<template>
  <overlay-scrollbars class="page page-balance">
    <div class="box box_myBalance">
      <div class="header">
        <div class="title">My balance</div>
        <div class="block">
          <Stat class="img" />
          <div class="block_content">
            <div class="subtxt">Balance status</div>
            <div class="txt">{{ number_format(myBalance.paid_balance, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <Graph class="img" />
          <div class="block_content">
            <div class="subtxt">Total request cost</div>
            <div class="txt">{{ number_format(myBalance.balance, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <CircleLoad class="img" />
          <div class="block_content">
            <div class="subtxt">Must be covered cost <ArrowFlat class="high" /></div>
            <div class="txt">{{ number_format(myBalance.balance - myBalance.paid_balance, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block block-paymentDeadline">
          Payment Deadline:
          <span class="val">{{ myBalance.paymentDeadline.days }}</span> <span class="desc">days</span>
          <span class="val">{{ myBalance.paymentDeadline.hours }}</span> <span class="desc">hours</span>
        </div>
      </div>
    </div>
    <div class="box box_appList">
      <div class="header">
        <div class="title">YOUR APPLICATION LIST</div>
      </div>
      <div class="content">
        <!--<div class="block">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
          </div>
          <Table :key="bankAccKey" @apply="apply" :options="bankAcc" />
        </div>
        <div class="block">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
          </div>
          <Table :key="merchantAccKey" @apply="apply" :options="merchantAcc" />
        </div>-->
        <div class="block">
          <div class="hdr">
            <div class="row">
              <div class="col">
                <Bank2 class="img" /><div class="ttl">Requests</div>
              </div>
              <div class="col">
                <span class="total">
                  Total: {{ number_format(myBalance.license_offers_balance, 2, '.', ' ') }}€
                </span>
                <span>
                  Paid: {{ number_format(myBalance.paid_license_offers_balance, 2, '.', ' ') }}€
                </span>
                <span class="covered">
                  Must be covered: {{ number_format(myBalance.license_offers_balance - myBalance.paid_license_offers_balance, 2, '.', ' ') }}€
                </span>
              </div>
            </div>
          </div>
          <Table :key="licenseOfferKey" :options="licenseOffers" />
        </div>
        <div class="block">
          <div class="hdr">
            <div class="row">
              <div class="col">
                <Bank2 class="img" /><div class="ttl">Legal Service</div>
              </div>
              <div class="col">
                <span class="total">
                  Total: {{ number_format(myBalance.legal_services_balance, 2, '.', ' ') }}€
                </span>
                <span>
                  Paid: {{ number_format(myBalance.paid_legal_services_balance, 2, '.', ' ') }}€
                </span>
                <span class="covered">
                  Must be covered: {{ number_format(myBalance.legal_services_balance - myBalance.paid_legal_services_balance, 2, '.', ' ') }}€
                </span>
              </div>
            </div>
          </div>
          <Table :key="legalAccKey" @apply="cancelLegal" :options="legalService" />
        </div>
        <div class="block">
          <div class="hdr">
            <div class="row">
              <div class="col">
                <Bank2 class="img" /><div class="ttl">Ready licenses</div>
              </div>
              <div class="col">
                <span class="total">
                  Total: {{ number_format(myBalance.business_offers_balance, 2, '.', ' ') }}€
                </span>
                <span>
                  Paid: {{ number_format(myBalance.paid_business_offers_balance, 2, '.', ' ') }}€
                </span>
                <span class="covered">
                  Must be covered: {{ number_format(myBalance.business_offers_balance - myBalance.paid_business_offers_balance, 2, '.', ' ') }}€
                </span>
              </div>
            </div>
          </div>
          <Table :key="readyLicensesKey" :options="readyLicenses" />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import moment from 'moment'
import Info from '@/components/elem/Info.vue'
import Table from '@/components/elem/Table.vue'

import Stat from '@/components/img/Stat.vue'
import Graph from '@/components/img/Graph.vue'
import CircleLoad from '@/components/img/CircleLoad.vue'
import ArrowFlat from '@/components/img/ArrowFlat.vue'
import Bank2 from '@/components/img/Bank2.vue'
import CreditCards from '@/components/img/CreditCards.vue'
import Close from '@/components/img/Close.vue'

export default {
  components: {
    Info,
    Table,
    Stat,
    Graph,
    CircleLoad,
    ArrowFlat,
    Bank2,
    CreditCards,
    Close,
  },
  data: () => ({
    myBalance: {
      balance: 0,
      paid_balance: 0,
      business_offers_balance: 0,
      license_offers_balance: 0,
      legal_services_balance: 0,
      paid_business_offers_balance: 0,
      paid_license_offers_balance: 0,
      paid_legal_services_balance: 0,
      paymentDeadline: {
        days: '',
        hours: ''
      },
    },
    bankAccKey: 0,
    merchantAccKey: 0,
    legalAccKey: 0,
    licenseOfferKey: 0,
    readyLicensesKey: 0,
    bankOffersData: [],
    merchantOffersData: [],
    readyLicensesData: [],
    licenseOfferData: [],
    legalData: [],
    bankTotal: 0,
    merchantTotal: 0,
    legalTotal: 0,
    licenseOffersTotal: 0,
    readyLicensesTotal: 0,
    bankAcc: {
      colsWidth: ['3%', '10%', '10%', '12%', '13%', '15%', '15%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Bank', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Account', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    },
    merchantAcc: {
      colsWidth: ['3%', '10%', '10%', '12%', '13%', '15%', '15%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Bank', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Zone', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    },
    legalService: {
      colsWidth: ['3%', '13%', '15%', '15%', '32%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Legal Co.', info: null },
        { ttl: 'Type of Services', info: null },
        { ttl: 'Price', info: null },
        { ttl: 'Description of Benefit', info: null },
        { ttl: 'Service time deadline', info: null },
        { ttl: 'Paid', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    },
    licenseOffers: {
      colsWidth: ['4%', '16%', '16%', '16%', '16%', '10%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'License Type', info: null },
        { ttl: 'Price', info: null },
        { ttl: 'Status', info: null },
        { ttl: 'Progress stage', info: null },
        { ttl: 'Deadline at', info: null },
        { ttl: 'Paid', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    },
    readyLicenses: {
      colsWidth: ['4%', '18%', '18%', '18%', '18%', '18%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Type of Business', info: null },
        { ttl: 'License Type', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Description', info: null },
        { ttl: 'Price', info: null },
        { ttl: 'Paid', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    }
  }),
  created () {
    this.getBalanceSettings()
    //this.getAppliedBankOffers()
    //this.getAppliedMerchantOffers()
    this.getAppliedLegalServices()
    this.getLicenseOffers()
    this.getReadyLicenses()
    if (this.$store.state.user.balance) {
      this.myBalance.balance = this.$store.state.user.balance
      this.myBalance.paid_balance = this.$store.state.user.paid_balance
      this.myBalance.business_offers_balance = this.$store.state.user.business_offers_balance
      this.myBalance.license_offers_balance = this.$store.state.user.license_offers_balance
      this.myBalance.legal_services_balance = this.$store.state.user.legal_services_balance
      this.myBalance.paid_business_offers_balance = this.$store.state.user.paid_business_offers_balance
      this.myBalance.paid_license_offers_balance = this.$store.state.user.paid_license_offers_balance
      this.myBalance.paid_legal_services_balance = this.$store.state.user.paid_legal_services_balance
    } else {
      this.myBalance.balance = 0
      this.myBalance.paid_balance = 0
      this.myBalance.business_offers_balance = 0
      this.myBalance.license_offers_balance = 0
      this.myBalance.legal_services_balance = 0
      this.myBalance.paid_business_offers_balance = 0
      this.myBalance.paid_license_offers_balance = 0
      this.myBalance.paid_legal_services_balance = 0
    }
  },
  methods: {
    getBalanceSettings () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'balance-settings/' + this.$store.state.user.id)
        .then(function (response) {
          if (response.data.success) {
            that.myBalance.paymentDeadline.days = response.data.balance_settings.payment_deadline_days
            that.myBalance.paymentDeadline.hours = response.data.balance_settings.payment_deadline_hours
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    /*getAppliedBankOffers () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'offers/applied/bank-accounts')
        .then(function (response) {
          that.bankAcc.body = []
          that.bankOffersData = response.data
          let totalPrice = 0
          that.bankOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
            const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
            that.bankAcc.body.push([
              el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
          })
          that.bankTotal = totalPrice
          //that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.bankAcc.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },*/
    /*getAppliedMerchantOffers () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'offers/applied/merchant-accounts')
        .then(function (response) {
          that.merchantAcc.body = []
          that.merchantOffersData = response.data
          let totalPrice = 0
          that.merchantOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
            const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
            that.merchantAcc.body.push([
              el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
          })
          that.merchantTotal = totalPrice
          ///that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.merchantAcc.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },*/
    getAppliedLegalServices () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'legal-service/applied?with_transactions=true')
        .then(function (response) {
          that.legalService.body = []
          that.legalData = response.data
          let totalPrice = 0
          that.legalData.forEach(el => {
            that.legalService.body.push([
              el.serial_number, el.legal_name, el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
              el.benefit_description, { type: 'deadline-balance', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id },
              {type: 'transactions', transactions: el.userTransactions, total: that.calculateTotalTransactionsSum(el.userTransactions), price: +el.price}
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.price)
          })
          that.legalTotal = totalPrice
          //that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.legalService.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getLicenseOffers () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'license-offers?with_transactions=true&progress_stage=30')
        .then(function (response) {
          that.licenseOffers.body = []
          that.licenseOfferData = response.data.data
          let totalPrice = 0
          that.licenseOfferData.forEach(el => {
            that.licenseOffers.body.push([
              el.id, el.countries[0].name, el.licenseTypes[0].name, that.number_format(el.price, 2, '.', ' ') + '€',
              el.status.name, el.progress_stage, { type: el.deadline_at ? 'deadline-balance' : 'balance', days: moment.duration(moment(el.deadline_at).diff(moment())).days(), days_text: 0, hours: moment.duration(moment(el.deadline_at).diff(moment())).hours(), hours_text: 0, id: el.id },
              {type: 'transactions', transactions: el.userTransactions, total: that.calculateTotalTransactionsSum(el.userTransactions), price: +el.price}
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.price)
          })
          that.licenseOffersTotal = totalPrice
          //that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.licenseOffers.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getReadyLicenses () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'business-offers/applied?with_transactions=true' )
        .then(function (response) {
          that.readyLicenses.body = []
          that.readyLicensesData = response.data
          let totalPrice = 0
          that.readyLicensesData.forEach(el => {
            that.readyLicenses.body.push([
              el.id, el.business_type, el.license_type, el.country ? el.country.name : '', el.description, that.number_format(el.price, 2, '.', ' ') + '€',
              { type: 'balance', id: el.id },
              {type: 'transactions', transactions: el.userTransactions, total: that.calculateTotalTransactionsSum(el.userTransactions), price: +el.price}
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.price)
          })
          that.readyLicensesTotal = totalPrice
          //that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.readyLicenses.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    calculateTotalTransactionsSum(transactions){
      let sum = 0
      
      if (Array.isArray(transactions)) {
        transactions.forEach((transaction, i) => {
          sum += +transaction.amount
        })
      } else if (typeof transactions === 'object' && transactions !== null) {
        Object.values(transactions).forEach((transaction, i) => {
          sum += +transaction.amount
        })
      }

      return sum
    },
    apply (payload) {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'offers/toogle-apply/' + payload.id)
        .then((response) => {
          const res = response.data
          if (payload.applyType == 'bank-offer') {
            if (!res.applied) {
              that.bankAcc.body = []
              that.bankOffersData = that.bankOffersData.filter(offer => offer.id !== payload.id)
              let totalPrice = 0
              that.bankOffersData.forEach(el => {
                const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
                const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
                that.bankAcc.body.push([
                  el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
                ])
                totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
              })
              that.bankTotal = totalPrice
              //that.calculateRequestCost()
              totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
              that.bankAcc.footer.val = totalPrice
              that.bankAccKey++
            }
          } else if (payload.applyType == 'merchant-offer') {
            that.merchantAcc.body = []
            that.merchantOffersData = that.merchantOffersData.filter(offer => offer.id !== payload.id)
            let totalPrice = 0
            that.merchantOffersData.forEach(el => {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.merchantAcc.body.push([
                el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
              ])
              totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
            })
            that.merchantTotal = totalPrice
            //that.calculateRequestCost()
            totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
            that.merchantAcc.footer.val = totalPrice
            that.merchantAccKey++
          }

        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    cancelLegal(payload) {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'legal-service/toogle-apply/' + payload.id)
        .then((response) => {
          const res = response.data
          if (!res.applied) {
            that.legalService.body = []
            that.legalData = that.legalData.filter(legal => legal.id !== payload.id)
            let totalPrice = 0
            that.legalData.forEach(el => {
              that.legalService.body.push([
                el.serial_number, el.legal_name, el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
                el.benefit_description, { type: 'deadline-balance', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
              ])
              totalPrice = parseFloat(totalPrice) + parseFloat(el.price)
            })
            that.legalTotal = totalPrice
            //that.calculateRequestCost()
            that.legalService.footer.val = that.number_format(totalPrice, 2, '.', ' ') + '€'
            that.legalAccKey++
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    /*calculateRequestCost () {
      this.myBalance.trc = parseFloat(this.bankTotal) + parseFloat(this.merchantTotal) + parseFloat(this.legalTotal)  
      this.myBalance.mbcc = this.myBalance.trc - this.myBalance.status
    }*/
  }
}
</script>

<style lang="scss" scoped>
.page-balance {
  .box {
    &_myBalance {
      background: transparent;
      color: #09090b;
      box-shadow: none;

      .header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        //background-image: url('/img/group_61.png');
        //background-position: 102% -0.5rem;
        background-repeat: no-repeat;
        background-size: auto;
        border-radius: 14px;
        color: #09090b;
        .title{
          color: #09090b;
          padding-left: 0;
          &:before{
            content: none;
          }
        }
        .block {
          display: flex;
          padding: .25rem 1rem .75rem;

          svg.img {
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 10px;
            fill: #09090b;
          }

          &_content {
            padding: 0 .75rem;

            .subtxt {
              position: relative;
              font-size: 10px;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 0 4px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }

            .txt {
              display: flex;
              align-items: center;
              font-size: 1.25rem;
              line-height: 1.1;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 6px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }
          }

          &-paymentDeadline {
            padding: 0.25rem 1.5rem;
            margin-left: auto;
            background: #272E35;
            border-radius: 6px;
            align-items: baseline;
            color: #fff;

            .val {
              margin: 0 .25rem 0 .5rem;
              font-size: 22px;
              color: #1763fb;
            }

            .desc {
              margin: 0 .25rem 0 -0.125rem;
              font-size: 14px;
            }
          }
        }
      }
    }

    &_appList {
      padding: 0;
      background: transparent;
      box-shadow: none;
      .title{
        color:#09090b;
        padding:0;
        &:before{
          content: none;
        }
      }
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;
          padding: 0;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;
            .ttl{
              color:#09090b;
            }

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
              filll: #09090b;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .col{
      flex: 0 0 auto;
      align-items: center;
      color: #09090b;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      font-weight: bold;
      .total{
        color: #1763fb;
      }
      .covered{
        color: #B03636;
      }
    }
  }
}
</style>